import * as React from 'react';
import { navigate } from 'gatsby-link';
import Layout from '../../components/Layout';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import participate1 from '../../img/participate/participate1.png';
import participate2 from '../../img/participate/participate2.png';
import participate3 from '../../img/participate/participate3.png';
import participate4 from '../../img/participate/participate4.png';
import autosize from 'autosize';

function encode(data) {
  const formData = new FormData();

  for (const key of Object.keys(data)) {
    formData.append(key, data[key]);
  }
  return formData;
}

export default class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isValidated: false,
      fileUploadStatus: '',
      uploadedFileName: '',
      uploadedFileSize: 0,
      ctaText: 'upload here...',
      projects: [],
    };
  }
  componentDidMount() {
    this.textarea.focus();
    autosize(this.textarea);
  }
  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  handleTitleBlur = (e) => {
    if (this.projects.length > 0) {
      for (let i = 0; i < this.projects.length; i++) {
        if (e.target.value.toLowerCase().trim() === this.projects[i].node.frontmatter.title.toLowerCase()) {
          e.target.setCustomValidity('project name already exists! Please choose a unique project name.');
          e.target.reportValidity();
          return false;
        } else {
          e.target.setCustomValidity('');
        }
      }
    }
  }

  handleAttachment = (e) => {
    e.preventDefault();
    this.setState({ uploadedFileName: e.target.files[0].name });
    if (e.target.files[0].size > 5000000) {
      e.target.setCustomValidity('Photo is too large!');
      e.target.reportValidity();
      const fileIsTooLarge = 'please choose another photo (400x400px and smaller than 5MB)';
      this.setState({ fileUploadStatus: fileIsTooLarge });
      return false;
    } else {
      e.target.setCustomValidity('');
      const uploadSuccessful = `selected photo: ${e.target.files[0].name}`;
      this.setState({ [e.target.name]: e.target.files[0] });
      this.setState({ fileUploadStatus: uploadSuccessful });
      this.setState({ ctaText: 'upload another...' });
    }
  };
  handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    fetch('/', {
      method: 'POST',
      body: encode({
        'form-name': form.getAttribute('name'),
        ...this.state,
      }),
    })
      .then(() => navigate(form.getAttribute('action')))
      .catch((error) => alert(error));
  };

  render() {
    const { data, location } = this.props;
    let { state = {} } = location;
    if (state === null) {
      state = { responseToProject: 'community' };
    }
    const { responseToProject } = state;
    const { edges: posts } = data.allMarkdownRemark;
    this.projects = posts;
    return (
      <Layout>
        <section className="section">
          <div className="container">
            <div className="content">
              <h4>rongin shagor invites anyone to participate in an artistic process/response. </h4>
              <p>
                Dreams, visions and associative imagination of poetry are reinforced by a collective
                growth of individual, sequential works of art - beginning with the aforementioned
                poem by May Ayim. The reactions of interdisciplinary artists to this work have
                shaped “rongin shagor”.
              </p>
              <p>
                Now, we invite you to continue this dialogue by participating in the process of
                artistic responses by sending your own creative interpretation. You can contribute
                to existing creation.
              </p>
              <div className="">
                <div className="img-container">
                  <img
                    className="participate-image pImg1"
                    src={participate1}
                    alt="participate step 1"
                  />
                  <img
                    className="participate-image pImg3"
                    src={participate3}
                    alt="participate step 3"
                  />
                  <img
                    className="participate-image pImg2"
                    src={participate2}
                    alt="participate step 2"
                  />
                  <img
                    className="participate-image pImg4"
                    src={participate4}
                    alt="participate step 4"
                  />
                </div>
              </div>
              <p>
                For questions: Please don't hesitate to contact us on:{' '}
                <span
                  className="has-text-light pointer is-family-primary is-size-5"
                  onClick={(e) => {
                    window.location.href = 'mailto:contact@ronginshagor.com ';
                    e.preventDefault();
                  }}
                >
                  contact@ronginshagor.com
                </span>
              </p>
              <p>
                As soon as your response is uploaded, your interpretation will be part of this
                intercultural dialogue. Thus, your name will appear under the Community tab with……
              </p>
              <p>
                Only upload sound or images for which you own or have the necessary licenses,
                rights, consents, and permissions to use. This site operates under the Creative
                Common license CC BY-NC-SA (Creative Commons Attribution-Non Commercial-Share
                Alike). (All material * uploaded on this site is public domain, and can be
                reinterpreted creatively by others.)
              </p>
              <p>
                If you experience any trouble uploading, or if your connection is too slow or
                unreliable, please contact us to find other means to send your creation:{' '}
                <span
                  className="has-text-light pointer is-family-primary is-size-5"
                  onClick={(e) => {
                    window.location.href = 'mailto:contact@ronginshagor.com ';
                    e.preventDefault();
                  }}
                >
                  contact@ronginshagor.com
                </span>
              </p>
              <h4 className="has-text-light">Participation form</h4>
              <form
                name="submit"
                method="POST"
                action="/submit/thanks/"
                encType="multipart/form-data"
                data-netlify="true"
                data-netlify-honeypot="bot-field"
                onSubmit={this.handleSubmit}
              >
                {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
                <input type="hidden" name="form-name" value="submit" />
                <div hidden>
                  <label>
                    Don’t fill this out: <input name="bot-field" onChange={this.handleChange} />
                  </label>
                </div>
                <div className="field">
                  <label className="label is-size-4" htmlFor={'name'}>
                    1. your name
                  </label>
                  <p className="form-info-text">artist name</p>
                  <div className="control">
                    <input
                      className="input"
                      type={'text'}
                      name={'name'}
                      onChange={this.handleChange}
                      id={'name'}
                      placeholder="e.g. Ellina Example"
                      required={true}
                    />
                  </div>
                </div>
                <div className="field">
                  <label className="label is-size-4" htmlFor={'pronoun'}>
                    2. your pronoun
                  </label>
                  <p className="form-info-text">We want to address you the right way</p>
                  <div className="control">
                    <input
                      className="input"
                      type={'text'}
                      name={'pronoun'}
                      onChange={this.handleChange}
                      id={'pronoun'}
                      placeholder="e.g. she/her"
                      required={true}
                    />
                  </div>
                </div>
                <div className="field">
                  <label className="label is-size-4" htmlFor={'email'}>
                    3. your e-mail
                  </label>
                  <p className="form-info-text">In case we have further questions about your contribution</p>
                  <div className="control">
                    <input
                      className="input"
                      type={'email'}
                      name={'email'}
                      onChange={this.handleChange}
                      id={'email'}
                      required={true}
                      placeholder="ellina@email.com"
                    />
                  </div>
                </div>
                <div className="field">
                  <label className="label is-size-4" htmlFor={'bio'}>
                    4. your bio
                  </label>
                  <p className="form-info-text">
                    max. 400 characters
                  </p>
                  <div className="control">
                    <textarea
                      ref={(c) => (this.textarea = c)}
                      className="textarea bio"
                      name={'bio'}
                      onChange={this.handleChange}
                      id={'bio'}
                      required={true}
                      placeholder="e.g. May Ayim is the pen name of May Optiz (born Sylvia Andler): she was an Afro-German poet, educator, and activist."
                      maxLength={400}
                    />
                  </div>
                </div>
                <div className="field">
                  <label className="label is-size-4" htmlFor="file">
                    5. a photo of you
                  </label>
                  <p className="form-info-text">
                    400x400px, jpeg or png
                  </p>
                  <div className="file pointer">
                    <span className="file-cta">
                      <span className="has-text-light is-family-primary">{this.state.ctaText}</span>
                    </span>
                    <input
                      className="file-input pointer"
                      type="file"
                      name="file"
                      id="file"
                      accept="image/png, image/jpeg, image/jpg"
                      onChange={this.handleAttachment}
                    />
                  </div>
                  {this.state.uploadedFileName ? (
                    <span className="has-text-light is-family-primary">{this.state.fileUploadStatus}</span>
                  ) : (
                    <React.Fragment />
                  )}
                </div>
                <div className="field">
                  <label className="label is-size-4" htmlFor={'title'}>
                    6. title of your work
                  </label>
                  <p className="form-info-text">
                    Please choose a title which is different from any other already existing title on the page! max 60 characters
                  </p>
                  <div className="control">
                    <input
                      className="input"
                      type={'text'}
                      name={'title'}
                      onBlur={this.handleTitleBlur}
                      id={'title'}
                      placeholder="e.g. Community"
                      required={true}
                      maxLength={60}
                    />
                  </div>
                </div>
                <div className="field">
                  <label className="label is-size-4" htmlFor={'description'}>
                    7. description of your artwork
                  </label>
                  <p className="form-info-text">project description, max 1000 characters</p>
                  <div className="control">
                    <textarea
                      ref={(c) => (this.textarea = c)}
                      className="textarea desc"
                      name={'description'}
                      onChange={this.handleChange}
                      id={'description'}
                      required={true}
                      placeholder="This is how life unfolds: in constant wandering, in constant search for meaning, on never-ending paths, streets and alleys, highways and byways - labyrinthine in their infinity, in their invitation to the wanderer: here, yet another fascinating corner, barely visible footprints on the earth, traces of silent vibrations on the unrelenting pavement. 1996 was such a year. The sad, even devastating, news of her passing. A young neophyte practicing on a discarded keyboard."
                      maxLength={1000}
                    />
                  </div>
                </div>
                <div className="field">
                  <label className="label is-size-4" htmlFor={'link'}>
                    8. upload artwork
                  </label>
                  <p className="form-info-text">
                    Please, upload the files of your project to a google drive or dropbox folder,
                    and give us access to it (
                    <span
                      className="has-text-light pointer is-family-primary is-size-5"
                      onClick={(e) => {
                        window.location.href = 'mailto:contact@ronginshagor.com ';
                        e.preventDefault();
                      }}
                    >
                      contact@ronginshagor.com
                    </span>
                    )!
                  </p>
                  <p className="form-info-text">
                    Don’t forget to check our{' '}
                    <span>
                      <a
                        className="has-text-primary is-size-6"
                        href="https://docs.google.com/document/d/10YnblwhRUUC0FFTVzbqjr1guFNflzVgXhMamOS7DVLk/edit"
                        target="_blank"
                        rel="noreferrer"
                      >
                        requirement document
                      </a>
                    </span>{' '}
                    for the right file formats!
                  </p>
                  <div className="control">
                    <input
                      className="input"
                      type={'url'}
                      name={'link'}
                      onChange={this.handleChange}
                      id={'link'}
                      placeholder="e.g. https://www.dropbox.com/myproject"
                      required={true}
                    />
                  </div>
                </div>
                <div className="field">
                  <label className="label is-size-4" htmlFor={'project'}>
                    9. inspiration
                  </label>
                  <p className="form-info-text">choose the artwork that inspired your work</p>
                  <div className="control">
                    <div className="select">
                      <select
                        value={this.state.project}
                        name={'project'}
                        id={'project'}
                        onChange={this.handleChange}
                        required={true}
                        defaultValue={responseToProject ? responseToProject : 'select'}
                      >
                        <option value="select" disabled className="has-text-light pointer">
                          select project
                        </option>
                        {posts &&
                          posts.map(({ node: post }) => (
                            <option value={post.frontmatter.title} key={post.id}>
                              {post.frontmatter.title}
                            </option>
                          ))}
                      </select>
                    </div>
                  </div>
                </div>
                <br></br>
                <div className="field">
                  <button id="submit" className="button is-link" type="submit">
                    submit project
                  </button>
                </div>
              </form>
            </div>
          </div>
        </section>
      </Layout>
    );
  }
}

Index.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
  location: PropTypes.object,
};

export const formQuery = graphql`
  query projectsQuery {
    allMarkdownRemark(
      sort: { order: ASC, fields: [frontmatter___title] }
      filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
    ) {
      edges {
        node {
          id
          frontmatter {
            title
          }
        }
      }
    }
  }
`;
